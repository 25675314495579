import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', meta: {title: 'Главная',}, component: () => import('./views/Home.vue') },
  { path: '/demo/', meta: {title: 'demo',}, component: () => import('./views/Analytic/Demo.vue') },

  // { path: '/user/', meta: {title: 'Пользователи'}, component: () => import('./views/User/Users') },
  {
    path: '/desktop/',
    meta: {title: 'Рабочий стол',},
    component: () => import('./views/Desktop/Desktop.vue')
  },

  { path: '/user/', redirect: { path: '/user/client/' }},
  { path: '/user/:type/', meta: {title: 'Пользователи'}, component: () => import('./views/User/Users')},
  { path: '/user/:type/:id/', meta: {title: 'Клиент'}, component: () => import('./views/User/User') },

  { path: '/visit/', meta: {title: 'Визиты за день',}, component: () => import('./views/Visit/Visits.vue') },
  { path: '/visit/list/', meta: {title: 'Визиты за период',}, component: () => import('./views/Visit/VisitsList.vue') },
  { path: '/userSpecPlans/', meta: {title: 'План работы специалистов',}, component: () => import('./views/UserSpec/UserSpecPlans.vue') },
  { path: '/price/', meta: {title: 'Прайс-лист',}, component: () => import('./views/Price/Price.vue') },
  { path: '/service/', meta: {title: 'Услуги',}, component: () => import('./views/Service/Services.vue') },
  { path: '/financeOld/', meta: {title: 'Финансы',}, component: () => import('./views/Finance/FinanceOld.vue') },
  { path: '/finance/', meta: {title: 'Финансы (тестирование)',}, component: () => import('./views/Finance/Finance.vue') },
  { path: '/settings/', meta: {title: 'Настройки'}, component: () => import('./views/Settings/Settings') },
  { path: '/analytic/', meta: {title: 'Аналитика',}, component: () => import('./views/Analytic/Analytics.vue') },

  { path: '/insur/', meta: {title: 'Страховые компании'}, component: () => import('./views/Insur/Insurs.vue') },

  {path: '/call/', redirect: { path: '/call/callGroup/' }},
  {path: '/call/:tab/', meta: {title: 'Телефония'}, component: () => import('./views/Call/Calls.vue')},

  //{path: '/call/callGroup/', alias: ['/call/task/'], meta: {title: 'Телефония', tab:0}, component: () => import('./views/Call/Calls.vue')},
  //{path: '/call/task/', alias: ['/call/callGroup/'], meta: {title: 'Телефония', tab:1}, component: () => import('./views/Call/Calls.vue')},
  //temporary
  {
    path: '/visitService/',
    meta: {
      title: 'Оказанные услуги',
    },
    component: () => import('./views/VisitService/VisitServices.vue')
  },

  { path: '/medPlans/', meta: {title: 'Планы лечения',}, component: () => import('./views/MedPlan/MedPlans.vue') },
  { path: '/medRecords/', meta: {title: 'Мед. записи',}, component: () => import('./views/MedRecord/MedRecords.vue') }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});
router.afterEach((to) => {
  document.title = (typeof to.meta=='object' ? to.meta : to.meta(to)).title
})

export default router
